@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 100px;
  padding-right: 100px;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

section {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.info-container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.header,
.section {
  margin-left: -100px;
  margin-right: -100px;

  padding-left: 100px;
  padding-right: 100px;
}

.blog-block {
  padding-top: 100px;
  padding-bottom: 100px;
}

.bg-blue {
  background-color: #075dc2;
}

.home-top-section {
  justify-content: center;
  align-items: center;
  background-position: right;
  height: auto;
  min-height: 624px;
  background-image: url("/public/bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #075dc2;
}

.arrow {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f2229cc;
}

.arrow-left {
  left: 10px;
  transform: rotate(180deg);
}

.arrow-right {
  right: 10px;
}

.blog-section {
  overflow: hidden;
  /* position: absolute;
  width: calc(100% - 20px);
  left: 0;
  padding-left: 20px; */
}

.swiper-slide {
  /* height: 700px !important; */
}
.swiper-slide:last-child {
  margin-right: 20px !important;
}

.slides {
  display: flex;
  transition: transform 0.5s ease;
}

.blog-item {
  width: 100%;
  min-height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  display: grid;
  grid-template-rows: 320px 220px;
  margin-right: 15px;
  color: white;
  transition: 0.3s;
  border-radius: 16px;
}

.blog-item img {
  margin-top: auto;
}

.rounded {
  border-radius: 0.5rem;
}

.advantages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.block {
  border-radius: 16px;
  text-align: left;
  background-color: white;
  --aspect-ratio: 1.4; /* Define the aspect ratio (height/width) */
  /* height: calc(200px * var(--aspect-ratio)); */
}

.faq-section {
  padding-top: 50px;
  padding-bottom: 100px;
}

.faq-questions {
  display: flex;
}
.logo-white path {
  fill: white;
}

.logo-black path {
  fill: #1f2229;
}

.burger-menu {
  overflow: auto;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  color: white !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 8px;

  transition: 0.3s;

  display: flex;
}

.burger-menu--closed {
  transform: translateY(-100%);
}

.faq-item {
  padding: 25px;
  border-radius: 0.7rem;
  max-height: 77px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.more-button {
  padding-top: 20px;
}

.expanded {
  max-height: 500px;
}

.faq-button i {
  font-size: 17px;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #075dc2;
}

.faq-button span {
  padding-left: 20px;
}

.expanded .faq-button i {
  transform: rotate(45deg);
  color: #1f2229;
}

.faq-button span {
  font-size: 20px;
}

.faq-add-information {
  padding-left: 35px;
}

.info-container .title {
  padding-bottom: 70px;
}

.bottom-home-section {
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: url("/public/home-bottom-section.jpg");
  background-position: right;
  background-size: cover;
}

button.rounded,
.default-button {
  width: 215px;
  height: 56px;
}

.default-link {
  padding: 16px;
  padding-right: 24px;
  padding-left: 24px;
}

.title-bottom-home-section {
  max-width: 550px;
  padding-bottom: 20px;
}

.black-condition a,
.black-condition h5 {
  color: #1f2229;
}

.black-condition .bg-white {
  background-color: #1f2229;
  color: white;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 1.5px solid #1f2229;
}

.black-condition .bg-white:hover {
  background-color: white;
  color: #1f2229;
}

.blog-item {
  .p-6 {
    flex: 1;
  }

  .w-full {
    min-height: 40%;
    object-fit: cover;
    padding-bottom: 10px;
  }
}

footer .container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 40px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select file";
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: #1f2229;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/*.text-left {*/
/*    text-align: left;*/
/*}*/

.flex-center {
  align-items: center;
  justify-content: center;
}

.black-button {
  transition: color 0.3s ease, background-color 0.3s ease;
  font-weight: 600;
  border: 1.5px solid #1f2229;
}

.black-button:hover {
  background-color: white;
  color: #1f2229;
}

.white-button-blue-transition {
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 1px solid #075dc2;
}

.white-button-blue-transition:hover {
  background-color: #075dc2;
  color: white;
  border-color: white;
}

.blue-button-white-transition {
  transition: color 0.3s ease, background-color 0.3s ease;
}

.blue-button-white-transition:hover {
  background-color: white;
  color: #1f2229;
}

.footer_bottom {
  width: 99vw;
  background-color: #2a2e37;
  padding-bottom: 1rem;
  margin-left: -100px;
  padding-left: 100px;
  margin-right: -100px;

  padding-right: 100px;
  border: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(60%);
  }
}

.faq-page {
  margin-left: 20px;
  margin-right: 20px;

  .title-faq {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    p {
      font-size: 19px;
    }

    span {
      cursor: pointer;
      outline: blue;
      color: blue;
      text-decoration: underline;
    }
  }

  .info-page {
    display: flex;
    margin-top: 50px;
    margin-bottom: 150px;

    .fixed {
      position: fixed;
      top: 20px;
    }

    .left-side {
      position: relative;
      width: 300px;
      h3 {
        margin-bottom: 15px;
      }

      .anchor-block {
        width: 300px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;

        max-width: 300px;
        a {
          color: lightgrey;
          padding-left: 15px;
          padding-bottom: 3px;
          border-left: 1.5px solid lightgrey;
          transition: 0.3s;
        }
        a:last-child {
          border-bottom-left-radius: 16px;
        }

        a:hover {
          color: #494c52;
        }

        a.active {
          color: #1f2229;
          border-left: 1.5px solid #1f2229;
        }
      }
    }

    .right-side {
      margin-left: 20px;
      flex: 1;

      .faq-section {
        padding-top: 20px;
        padding-bottom: 40px;
      }

      .faq-questions {
        display: flex;
        flex-direction: column;

        .faq-item {
          background-color: #f4f4f4;
          margin-bottom: 15px;
        }
      }

      .bottom-info {
        border-top: 1.5px solid #f4f4f4;
        border-bottom: 1.5px solid #f4f4f4;
        font-size: 19px;
        padding-top: 20px;
        padding-bottom: 20px;

        span {
          color: blue;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .updated-date {
    margin-top: 100px;
  }

  .faq-page.terms {
    h1 {
      padding-top: 10px;
    }
  }

  .terms-page {
    h2 {
      margin-bottom: 25px;
      font-size: 27px;
    }

    p {
      margin-bottom: 50px;
    }

    span {
      color: blue;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .separator-s13 {
  }
}

.recent-blogs-block {
  background-color: #f4f4f4;
  margin-left: -100px;
  padding-left: 100px;
  margin-right: -100px;

  padding-right: 100px;
  padding-bottom: 100px;

  .recent-blog-item {
    margin-top: 40px;
    display: flex;

    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      padding: 5px;
      border-radius: 10px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.information-page {
  p {
    white-space: break-spaces;
  }
  .separator-bl {
    background: linear-gradient(90.47deg, #075dc2 0%, #3b93fb 100%);
    height: 98px;
    margin-left: -100px;
    padding-left: 100px;
    margin-right: -100px;

    padding-right: 100px;
    margin-top: 60px;
  }

  .sector-2 {
    background-color: #f4f4f4;
    padding-top: 60px;
    margin-left: -100px;
    padding-left: 100px;
    margin-right: -100px;

    padding-right: 100px;
    padding-bottom: 60px;
    .four-blocks {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-top: 20px;

      h3 {
        color: #075dc2;
        margin-bottom: 10px;
      }
    }
  }

  .left-block {
    display: flex;

    .left-ds {
      display: flex;
      flex-direction: column;
      margin-right: 100px;
      justify-content: center;
    }

    .right-ds {
      padding-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .right-block {
    display: flex;

    .left-ds {
      display: flex;
      flex-direction: column;
      margin-left: 100px;
      justify-content: center;
    }

    .right-ds {
      padding-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    margin-bottom: 60px;
  }

  .bottom-section {
    background-color: #f4f4f4;
    padding-top: 60px;
    margin-left: -100px;
    padding-left: 100px;
    margin-right: -100px;

    padding-right: 100px;
    padding-bottom: 60px;

    .flex {
      margin-top: 40px;
      justify-content: space-between;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  max-height: 350px;
  overflow: auto;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 200px;
  padding: 8px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 16px;
}

.dropdown-content a {
  padding: 12px;
  color: #1f2229;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.dropdown-content a:hover {
  background-color: #c4c4c4;
}
.dropdown-content a svg g path {
  transition: 0.3s ease;
}
.dropdown-content a:hover svg g path {
  fill: black;
}

.scroll-to-top-button {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #9c9c9c;

  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 8px 12px;
  border-radius: 12px;
}

.scroll-to-top-button svg g path {
  transition: fill 0.3s ease;
}

.scroll-to-top-button:hover {
  background-color: #f4f4f4;
  color: black;
}

.scroll-to-top-button:hover svg g path {
  fill: black;
}

.next {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  a {
    color: #075dc2;
    text-decoration: underline;
  }
}

.special-123sd {
  i {
    width: 20px;
    height: 20px;
  }
}

.special-123sd .special-icon path {
  fill: white;
}
.black-condition .special-123sd .special-icon path {
  fill: black;
  transition: 0.3s;
}

.black-condition .special-123sd:hover .special-icon path {
  fill: white;
}

.absolute-123 {
  position: absolute !important;
}

.close-button-hidden {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.blog-item {
  .text-base {
    max-height: 100px;
    overflow: hidden;
  }
}

.video-container {
  cursor: pointer;
  width: 486px;
  height: 517px;
  position: relative;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 80px;
  height: 80px;
}

.react-datepicker__input-container input {
  width: 100%;
  margin-left: 0 !important;
  border-radius: 16px;
  padding: 0.5rem;
}

.watermark {
  position: absolute;
  transform: rotate(-25deg);
  bottom: 33%;
  opacity: 0.2;
  left: 25%;
}

textarea {
  resize: none;
}
