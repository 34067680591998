@media (max-width: 1100px) {
  body {
    padding-left: 40px;
    padding-right: 40px;
  }

  .information-page {
    .bottom-section {
      padding-left: 40px;
      padding-right: 40px;
      margin-left: -40px;
      margin-right: -40px;
    }
  }
  .information-page {
    & .sector-2 {
      padding-left: -40px;
      padding-right: -40px;
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  .header,
  .section {
    margin-left: -40px;
    padding-left: 40px;
    margin-right: -40px;
    padding-right: 40px;
  }

  /* .block {
        width: 31%;
    } */

  /* .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    } */

  .footer_bottom {
    margin-left: -47px;
    padding-left: 47px;
    margin-right: -47px;
    padding-right: 47px;
  }

  .modal-contact-form {
    min-width: 96%;
  }

  .faq-page {
    margin-left: 0;
    margin-right: 0;
  }

  .anchor-block a {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .about-us {
    .bottom-container {
      margin-left: -40px;
      padding-left: 40px;
      margin-right: -40px;
      padding-right: 40px;
    }
  }
  .home-top-section {
    background-position-x: 300px;
  }
  .information-page {
    .separator-bl {
      margin-left: -40px;
      padding-left: 40px;
      margin-right: -40px;
      padding-right: 40px;
    }
  }
  .bottom-home-section {
    background-position: 80% 50%;
  }
}

@media (max-width: 1023px) {
  .bottom-home-section {
    background-position: unset;
  }
}

@media (max-width: 1024px) {
  .info {
    padding: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-top-section {
    background-image: none;
  }
}

@media (max-width: 768px) {
  .information-page {
    .separator-bl {
      margin-left: -10px;
      padding-left: 10px;
      margin-right: -10px;
      padding-right: 10px;
    }
  }
  .home-top-section {
    padding-bottom: 10px;
    background-image: none;
    background-color: #075dc2;
  }

  .modal-contact-form {
    top: 0;
  }

  .main-info {
    padding-right: 0;
  }

  .block {
    width: 100%;
  }

  .slides {
    flex-direction: column;

    .blog-item {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .faq-section {
    flex-direction: column;

    .faq-questions {
      flex-direction: column;
    }

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    .right-section {
      padding-left: 0;
    }
  }

  .footer_bottom {
    margin-left: -9px;
    padding-left: 17px;
    margin-right: -17px;
    padding-right: 17px;
  }

  body {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header,
  .section,
  .bottom-section,
  .sector-2,
  .separator-bl {
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
  }

  .form-title {
    padding: 0;
    text-align: left;
    padding-bottom: 20px;
  }

  .form {
    width: 95%;
    padding: 10px;
    min-width: 0;
    padding-bottom: 40px;

    h3 {
      text-align: center;
    }
  }

  .finish_form {
    text-align: center;
  }

  .parent-container,
  .form-container-usual {
    padding: 20px;

    .flex {
      flex-direction: column;
    }

    .count {
      padding-bottom: 10px;
    }

    .inpt-12 input:last-child {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .document-upload-block {
    margin-top: 0px !important;

    .count.flex {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      flex-direction: row;
    }

    .image-upload-wrap.unfilled {
      border: none;
    }

    .filled {
      .flex {
        flex-direction: row;
        align-items: center;
      }

      .flex.drag-text {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .unfilled {
      .drag-text {
        display: none;
      }
    }

    .input-doc-file {
      margin-left: 0 !important;

      .fa-arrow-up {
        display: none;
      }

      .select-file-button {
        width: 100%;
      }
    }
  }

  .modal {
    flex-direction: column;
  }

  .form-bottom-section {
    .mx-auto {
      flex-direction: column;

      div {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .payment-block {
    flex-direction: column;

    .block-1 {
      width: 100% !important;
      padding-right: 0;
    }

    .text-1xl {
      width: 100% !important;
    }
  }

  .stripe-box {
    width: 100%;
    max-width: 96%;
    padding: 1rem !important;
    margin: 1rem !important;
  }

  .express-del {
    justify-content: center;
  }

  .info-checkout-container {
    flex-direction: column;

    .checkout-separator {
      height: 2px;
      width: 40%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .flex-col-mobile {
    flex-direction: column;

    a {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  .count-signature-dig {
    flex-direction: row !important;
  }

  .signature-container {
    margin-left: 0;
  }

  .about-us {
    .top-section {
      flex-direction: column;
    }

    .first-image {
      margin-bottom: 10px;
    }

    .middle-section {
      flex-direction: column;

      img {
        width: 100%;
      }

      .right-content {
        margin: 0;
        margin-top: 20px;

        .last-block {
          flex-direction: column;

          .text-center {
            margin-bottom: 15px;
          }
        }
      }
    }

    .bottom-container {
      margin-left: -10px;
      padding-left: 10px;
      margin-right: -10px;
      padding-right: 10px;
      .flex {
        flex-direction: column;

        .item-block {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .info-page {
    .left-side {
      display: none;
    }

    .right-side {
      margin-left: 0 !important;
    }
  }

  header {
    top: 0;
    position: sticky;
    z-index: 2;
    background-color: white;
    .flex {
      a {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: 11px;
      }
      i {
        font-size: 11px;
      }
    }
  }

  .form-bottom-section {
    margin-bottom: 20px;
  }

  .text-center-mobile {
    text-align: center;
  }

  .text-font-bold-mobile {
    font-weight: bold;
  }

  .choose-section-12 {
    flex-direction: column;

    a {
      margin-top: 10px;
      margin-right: 0;
      margin-left: 0 !important;
      text-align: center;
    }
  }

  .overflow-stages {
    scroll-behavior: smooth;
    overflow: hidden;
  }

  .form-stages {
    width: 1000px;
  }

  .flex-center-mobile {
    h1 {
      width: 100%;
    }
  }

  .full-width-mobile {
    width: 100% !important;
  }

  .left-ds {
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
  }

  .modal-signature {
    width: 100% !important;
  }

  .information-page {
    & .sector-2 {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: -10px;
      margin-right: -10px;
      .four-blocks {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .information-page {
    .bottom-section {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

@media (max-width: 1024px) {
  .left-block,
  .right-block {
    flex-direction: column;
  }
}
