.file-upload {
  position: relative;
  display: inline-block;
}

.file-upload-input {
  display: none;
}

.image-upload-wrap {
  border: 1px dashed #075dc2;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 25px;
  flex-wrap: wrap;
  cursor: pointer;
  z-index: 1;
}

.image-upload-wrap i {
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-trash-icon {
  color: gray;
  font-size: 24px;
  margin-right: 15px;
}

.image-dropping,
.file-upload-content.success {
  display: none;
}

.drag-text {
  text-align: center;
  padding: 18px 0;
  flex: 1;
}

.file-upload-btn {
  background: #1fb264;
  color: white;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.select-file-button {
  width: 108px;
  height: 48px;
  border: 1.5px solid #13131366;
  border-radius: 10px;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
