.about-us {
  .top-section {
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 60px;
  }

  .payment_placeholder {
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-section div {
    width: 70%;
  }

  .top-section h1 {
    flex: 1;
    padding-top: 0;
  }

  .top-section p {
    padding-top: 15px;
    flex: 1;
  }

  .top-section .separator {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .first-image {
    width: 100%;
    height: auto;
    max-height: 480px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  .middle-section {
    padding-bottom: 30px;
    img {
      border-radius: 16px;
      object-fit: cover;
      width: 384px;
      object-position: center;
      height: auto;
    }

    .right-content {
      flex: 1;
      background-color: #e8e8e8;
      margin-left: 20px;
      padding: 30px;
      padding-top: 35px;
      border-radius: 20px;

      .last-block {
        flex: 1;

        .text-center {
          display: flex;
          width: 100%;
          flex-direction: column;
          background-color: white;
          justify-content: center;
          align-items: center;
          gap: 20px;
          border-radius: 15px;
          padding: 20px;
          box-shadow: 2px 2px 10px 2px lightgrey;
        }
      }
    }
  }

  .bottom-container {
    background-color: #e8e8e8;
    margin-left: -100px;
    padding-left: 100px;
    margin-right: -100px;
    padding-right: 100px;
    padding-bottom: 80px;
    .item-block {
      flex: 1;
      background-color: white;
      border-radius: 15px;
      padding: 20px;
      margin-right: 20px;
    }

    .item-block:last-child {
      margin-right: 0;
    }
  }
}
