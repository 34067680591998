h1 {
  padding-top: 40px;
  font-size: 60px;
  padding-bottom: 20px;
}

.stripe-box h2 {
  border-bottom: 1.5px solid lightgray;
  padding-bottom: 10px;
  font-size: 20px;
  margin-bottom: 0.5rem;
}

.stripe-box div {
  border-bottom: 1.5px solid lightgray;
  padding-bottom: 10px;
}

.info-checkout-container {
  margin-top: 7rem;
  margin-bottom: 5rem;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.checkout-separator {
  width: 2px;
  background-color: lightgray;
  height: 82px;
}
