.form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1f2229;
  font-weight: 500;
}

.form-title {
  text-align: center;
}

.form-stages {
  padding-bottom: 40px;
}

.parent-container {
  padding: 30px;
  border-radius: 40px;
  background-color: #e8e8e8;
}

.form {
  width: 60%;
  min-width: 996px;
  display: flex;
  padding: 50px;
  margin-top: -60px;
  flex-direction: column;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.form-bottom-section {
  margin-bottom: 100px;
}

.finish_form {
  margin-bottom: 100px;
}

.inpt-12 input {
  flex-grow: 1;
}

.inpt-12 input:last-child {
  margin-left: 20px;
}

.form-section input {
  height: 56px;
  border: none;
}

.form-section textarea {
  border: none;
}

.continue {
  width: 119px;
  height: 56px;
  background-color: #1f2229;
  border: 1.5px solid #1f2229;
  border-radius: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* transition: scale 0.3s ease, color 0.3s ease; */
}

.stage {
  transition: background-color 0.3s ease, color 0.3s ease;
  /* transition: scale 0.3s ease, color 0.3s ease; */
  color: #1f2229;
  border: 1.5px solid lightgray;
}

.progress-item .active-stage {
  background-color: white;
  border: 1.5px solid #075dc2;
  color: #075dc2;
}

.progress-item .active-stage + div {
  color: #075dc2;
}

.form-container-usual div {
  flex-grow: 1;
}

.back-form-button {
  margin-right: 40px;
  width: 86px;
  background-color: white;
  border: 1.5px solid #13131366;
  color: #1f2229;
}

.date_input_form {
  padding-left: 8px;
}

.modal-background {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.auth-stage {
  margin-top: 20px;
}

.special_instructions {
  height: 168px !important;
}

.continue:hover {
  background-color: white;
  border-color: #13131366;
  color: #1f2229;
  /* scale: 1.1; */
}

.back-form-button:hover {
  background-color: #1f2229;
  color: white;
  /* scale: 1.1; */
}

.progress-item {
  overflow: hidden;
  position: sticky;
}

.progress-item .flex {
  background-color: #f4f4f4;
}

.only-notarization .file-block-upload:last-child {
  padding-top: 20px;
}

.express-tooltip {
  width: 454px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 2px lightgrey;
}

.express-delivary-icon {
  margin-left: 10px;
  cursor: pointer;
  color: gray;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.progress-item .completed-stage {
  background-color: #075dc2;
  border: 1.5px solid #075dc2;
  color: white;
}

.progress-item-separator {
  flex-grow: 1; /* grow to occupy remaining space */
  height: 2px;
  margin-right: -40px;
  margin-bottom: 20px;
  margin-left: -40px;
  background-color: lightgray;
  transition: background-color 0.3s ease;
}

.icon-form-special {
  color: white;
  background-color: #075dc2;
  padding: 10px;
  border-radius: 30px;
}

.icon-form-special-big {
  color: white;
  background-color: #075dc2;
  padding: 15px;
  font-size: 25px;
  border-radius: 50%;
}

.document-upload-block:last-child {
  margin-top: 50px;
}

.parent-container .custom-document-input {
  border: 1px solid #075dc2;
  padding: 5px;
  text-align: center;
}

.signature-container {
  background-color: white;
  margin-left: 40px;
  border-radius: 20px;
}

.progress-item-separator.completed-stage {
  background-color: #075dc2;
}
