.modal-signature img {
  border-radius: 16px;
}
.modal {
  display: flex;

  .info {
    padding: 40px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    .contact-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        margin-bottom: 8px;
      }

      i {
        margin-right: 10px;
      }

      .flex {
        align-items: center;
        font-size: 16px;
        margin-bottom: 8px;

        a {
          color: #075dc2;
        }
      }

      .flex:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-container {
    border-bottom-right-radius: 13px;
    border-top-right-radius: 13px;
    padding: 40px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #e8e8e8;

    form {
      padding-left: 40px;

      input {
        height: 50px;
        padding-bottom: 8px;
        margin-top: 0;
      }

      textarea {
        height: 120px;
        resize: none;
      }

      button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
